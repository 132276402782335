import cx from "classnames";
import QuestionType from "@olivahealth/graphql-server/src/domain/value-objects/QuestionType";
import SurveyStatus from "@olivahealth/graphql-server/src/domain/value-objects/SurveyStatus";
import {
  ArrowIcon,
  Button,
  LoadingSpinnerIcon,
  Text,
  RadioQuestionVariant,
} from "@olivahealth/oli-ui";

import useTranslation from "../../../hooks/useTranslation";
import NotFoundPage from "../../../rebrand/pages/NotFoundPage";
import Trans from "../../../rebrand/molecules/Trans";
import SingleChoiceRenderer from "./SingleChoiceRenderer";
import TextRenderer from "./TextRenderer";
import useNativeSurvey, {
  NativeSurveyAnswers,
  NativeSurveyFormInitialState,
  OnAnswersChanged,
  SurveyFlowToEventsMapping,
} from "./useNativeSurvey";
import {
  SurveyAnswerInput,
  SurveySubmissionMetadataInput,
} from "./__generated__/useNativeSurveyCreateMutation.graphql";
import { NativeSurveyTitle } from "./__generated__/useNativeSurveyQuery.graphql";
import { NativeSurveyFooter } from "./Footer";

type NativeSurveyVariant = "embed" | "fullPage";

export type NativeSurveyVariables = Record<string, unknown>;

interface Props {
  dismissButtonLabel?: string;
  formInitialState?: NativeSurveyFormInitialState;
  i18nPrefix?: string;
  id?: string;
  submissionId?: string | null;
  submissionStatus?: SurveyStatus;
  title?: NativeSurveyTitle;
  trackAnswers?: boolean;
  variables?: NativeSurveyVariables;
  metadata?: SurveySubmissionMetadataInput;
  onAnswersChanged?: OnAnswersChanged;
  onDismissButtonClick?: () => void;
  dismissOnlyOnFirstQuestion?: boolean;
  onSubmitSuccess?: (answers: NativeSurveyAnswers) => void;
  surveyFlowToEventsMapping?: SurveyFlowToEventsMapping;
  variant?: NativeSurveyVariant;
  singleChoiceRendererVariant?: RadioQuestionVariant;
}

interface RendererProps {
  handleSubValueChange: (optionValue: string, subValue: string) => void;
  handleOptionChosen: (optionId: string) => void;
  handleTextChange: (value: string) => void;
  initialAnswer: SurveyAnswerInput | null;
  isFullPage: boolean;
  question: any;
  singleChoiceRendererVariant?: RadioQuestionVariant;
}

export default function NativeSurvey({
  dismissButtonLabel,
  dismissOnlyOnFirstQuestion = false,
  formInitialState,
  i18nPrefix,
  id,
  submissionId,
  submissionStatus,
  title,
  trackAnswers = false,
  onAnswersChanged,
  onDismissButtonClick,
  onSubmitSuccess,
  surveyFlowToEventsMapping = {},
  variables = {},
  metadata = {},
  variant = "fullPage",
  singleChoiceRendererVariant,
}: Props): JSX.Element {
  const { t } = useTranslation("common", {
    keyPrefix: i18nPrefix ?? "nativeSurveys",
  });
  const {
    status,
    data: {
      currentQuestionData,
      isLastQuestion,
      isFirstQuestion,
      isSubmitting,
      currentQuestionIndex,
      currentAnswer,
      numberOfQuestions,
    },
    effects: {
      handleSubValueChange,
      handleOptionChosen,
      handleTextChange,
      navigateToPreviousQuestion,
      navigateToNextQuestion,
      submit,
    },
  } = useNativeSurvey({
    formInitialState,
    id,
    submissionId,
    submissionStatus,
    surveyFlowToEventsMapping,
    title,
    trackAnswers,
    onAnswersChanged,
    onSubmitSuccess,
    metadata,
  });

  const isFullPage = variant === "fullPage";

  const getCurrentQuestionRow = () => {
    return t("currentQuestionRow", {
      currentQuestion: currentQuestionIndex + 1,
      totalQuestions: numberOfQuestions,
    });
  };

  const renderBottomAction = () => {
    const onlyShowOnFirstQuestion = dismissOnlyOnFirstQuestion
      ? dismissOnlyOnFirstQuestion && isFirstQuestion
      : true;

    return (
      <div
        className={cx("flex justify-end", {
          "mt-8": isFullPage,
        })}
      >
        {isLastQuestion ? (
          <Button
            width="min"
            variant="primary"
            onClick={submit}
            disabled={isSubmitting}
            iconLeft={isSubmitting ? <LoadingSpinnerIcon /> : undefined}
          >
            {t(
              isSubmitting
                ? "submitting"
                : isFullPage
                  ? "submitFullPage"
                  : "submitEmbed",
            )}
          </Button>
        ) : currentQuestionData.needsConfirmation ? (
          <Button
            disabled={!currentAnswer?.chosenOption.value}
            onClick={navigateToNextQuestion}
            variant="primary"
            width="min"
          >
            {t("continueButton")}
          </Button>
        ) : null}
        {!isLastQuestion && dismissButtonLabel && onlyShowOnFirstQuestion && (
          <Button variant="link" onClick={onDismissButtonClick}>
            <Text color="secondary" variant="link">
              {dismissButtonLabel}
            </Text>
          </Button>
        )}
      </div>
    );
  };

  if (status === "error") {
    return <NotFoundPage />;
  }

  return (
    <div
      className={cx("relative w-full flex flex-col", {
        "xl:w-3/4": isFullPage,
      })}
    >
      <span
        className={cx("flex w-full items-center", {
          "justify-between": !isFirstQuestion,
          "justify-end": isFirstQuestion,
          "h-10 my-5": isFullPage,
          "h-5 mb-4": !isFullPage,
        })}
      >
        {!isFirstQuestion && (
          <div className={isFullPage ? undefined : "relative -left-3"}>
            <Button
              onClick={navigateToPreviousQuestion}
              aria-label="navigate to previous question"
              variant="icon"
              disabled={isSubmitting}
            >
              <ArrowIcon direction={isFullPage ? "up" : "left"} />
            </Button>
          </div>
        )}
        <span
          className={cx("flex whitespace-nowrap text-sm font-bold", {
            "md:hidden": isFullPage,
          })}
        >
          <Text variant="label" color="secondary">
            {getCurrentQuestionRow()}
          </Text>
        </span>
      </span>
      <div className="flex grow items-start gap-x-7">
        <span
          className={cx("hidden whitespace-nowrap text-sm font-bold", {
            "md:flex": isFullPage,
          })}
        >
          <Text variant="label" color="secondary">
            {getCurrentQuestionRow()}
          </Text>
        </span>
        <div className="flex flex-col justify-between w-full h-full">
          <div>
            <div
              key={currentQuestionData.id}
              className="mb-6 flex animate-fade-in-up flex-col"
            >
              {currentQuestionData.header && (
                <Text
                  variant="label"
                  component="p"
                  color="secondary"
                  gutter="sm"
                >
                  <Trans
                    i18nKey={currentQuestionData.header}
                    values={variables}
                  />
                </Text>
              )}
              <Text
                variant={isFullPage ? "h3" : undefined}
                component={isFullPage ? "h1" : undefined}
                gutter={currentQuestionData.subtitle ? "md" : "xl"}
                weight="bold"
              >
                <Trans i18nKey={currentQuestionData.title} values={variables} />
              </Text>
              {currentQuestionData.subtitle && (
                <Text color="secondary" gutter="xl">
                  <Trans
                    i18nKey={currentQuestionData.subtitle}
                    values={variables}
                  />
                </Text>
              )}
              <Renderer
                handleOptionChosen={handleOptionChosen}
                handleTextChange={handleTextChange}
                initialAnswer={currentAnswer}
                isFullPage={isFullPage}
                question={currentQuestionData}
                handleSubValueChange={handleSubValueChange}
                singleChoiceRendererVariant={singleChoiceRendererVariant}
              />
            </div>
            {renderBottomAction()}
          </div>
          <NativeSurveyFooter
            notice={currentQuestionData.notice}
            variables={variables}
          />
        </div>
      </div>
    </div>
  );
}

export function Renderer({
  handleOptionChosen,
  handleTextChange,
  initialAnswer,
  isFullPage,
  question,
  handleSubValueChange,
  singleChoiceRendererVariant,
}: RendererProps): JSX.Element {
  switch (question.type) {
    case QuestionType.SINGLE_CHOICE:
      return (
        <SingleChoiceRenderer
          initialAnswer={initialAnswer}
          onOptionChosen={handleOptionChosen}
          question={question}
          handleSubValueChange={handleSubValueChange}
          variant={singleChoiceRendererVariant}
        />
      );
    case QuestionType.TEXT:
      return (
        <TextRenderer
          initialAnswer={initialAnswer}
          isFullPage={isFullPage}
          onTextChange={handleTextChange}
          question={question}
        />
      );
    default:
      return (
        <div className="flex flex-col gap-y-3">
          Default renderer
          <Button onClick={() => handleOptionChosen("test")} variant="primary">
            Move along
          </Button>
        </div>
      );
  }
}
