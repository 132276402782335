/**
 * @generated SignedSource<<bd13e70a2ce15ba555502b327d547d9c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type NativeSurveyQuestionType = "HEADER" | "MULTIPLE_CHOICES" | "SINGLE_CHOICE" | "TEXT" | "TOPICS";
export type SurveySubmissionStatusEnum = "COMPLETED" | "PENDING" | "SKIPPED";
export type SurveySubmissionMetadataInput = {
  groupSessionId?: string | null | undefined;
  professionalId?: string | null | undefined;
  sessionId?: string | null | undefined;
  therapyId?: string | null | undefined;
};
export type SurveyAnswerInput = {
  chosenOption: OptionInput;
  id: string;
  type: NativeSurveyQuestionType;
};
export type OptionInput = {
  label: string;
  score: number;
  subValue?: string | null | undefined;
  sublabel?: string | null | undefined;
  value: string;
};
export type useNativeSurveyCreateMutation$variables = {
  answers: ReadonlyArray<SurveyAnswerInput>;
  metadata: SurveySubmissionMetadataInput;
  status?: SurveySubmissionStatusEnum | null | undefined;
  surveyId: string;
};
export type useNativeSurveyCreateMutation$data = {
  readonly createSurveySubmission: {
    readonly __typename: "SurveySubmissionCreated";
    readonly id: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type useNativeSurveyCreateMutation = {
  response: useNativeSurveyCreateMutation$data;
  variables: useNativeSurveyCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "answers"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "metadata"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "surveyId"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "answers",
        "variableName": "answers"
      },
      {
        "kind": "Variable",
        "name": "metadata",
        "variableName": "metadata"
      },
      {
        "kind": "Variable",
        "name": "status",
        "variableName": "status"
      },
      {
        "kind": "Variable",
        "name": "surveyId",
        "variableName": "surveyId"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "createSurveySubmission",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "type": "SurveySubmissionCreated",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useNativeSurveyCreateMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "useNativeSurveyCreateMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "0073ccd645d3689cda43cd27360ebcb6",
    "id": null,
    "metadata": {},
    "name": "useNativeSurveyCreateMutation",
    "operationKind": "mutation",
    "text": "mutation useNativeSurveyCreateMutation(\n  $surveyId: ID!\n  $metadata: SurveySubmissionMetadataInput!\n  $answers: [SurveyAnswerInput!]!\n  $status: SurveySubmissionStatusEnum\n) {\n  createSurveySubmission(surveyId: $surveyId, metadata: $metadata, answers: $answers, status: $status) {\n    __typename\n    ... on SurveySubmissionCreated {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f0ab2652e65746a99373b1fe31b1d521";

export default node;
