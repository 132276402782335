/**
 * @generated SignedSource<<c90ece529157f50d9484bafcb43a866a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NativeSurveyQuestionType = "HEADER" | "MULTIPLE_CHOICES" | "SINGLE_CHOICE" | "TEXT" | "TOPICS";
export type NativeSurveyTitle = "CAREER_COACHING_PROGRESS" | "CHECK_IN" | "EMPLOYEE_NPS" | "EMPLOYEE_UNSATISFIED_WITH_PROFESSIONAL" | "POST_SESSION_SATISFACTION" | "PROFESSIONAL_NPS" | "PROGRESS_CHECK_PHQ2_GAD2" | "PROGRESS_CHECK_PHQ9_GAD7" | "PROGRESS_CHECK_THERAPY_BELOW_CASENESS" | "RISK_ASSESSMENT" | "SYMPTOMS_TRACKER" | "TRIAL_FOLLOW_UP" | "TRIAL_GETTING_STARTED";
export type useNativeSurveyQuery$variables = {
  id?: string | null | undefined;
  title?: NativeSurveyTitle | null | undefined;
};
export type useNativeSurveyQuery$data = {
  readonly nativeSurvey: {
    readonly __typename: "NativeSurvey";
    readonly id: string;
    readonly questions: ReadonlyArray<{
      readonly groupId: string | null | undefined;
      readonly header: string | null | undefined;
      readonly id: string;
      readonly logic: ReadonlyArray<{
        readonly requiredAnswers: ReadonlyArray<{
          readonly answerIds: ReadonlyArray<string>;
          readonly questionId: string;
        }>;
      }>;
      readonly needsConfirmation: boolean | null | undefined;
      readonly notice: string | null | undefined;
      readonly options: ReadonlyArray<{
        readonly label: string;
        readonly score: number;
        readonly subValue: string | null | undefined;
        readonly sublabel: string | null | undefined;
        readonly value: string;
      }>;
      readonly preSelectedOptionValue: string | null | undefined;
      readonly subtitle: string | null | undefined;
      readonly title: string;
      readonly type: NativeSurveyQuestionType;
      readonly " $fragmentSpreads": FragmentRefs<"SingleChoiceRenderer_question" | "TextRenderer_question">;
    }>;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type useNativeSurveyQuery = {
  response: useNativeSurveyQuery$data;
  variables: useNativeSurveyQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "title"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "title",
    "variableName": "title"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "groupId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "header",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "QuestionLogic",
  "kind": "LinkedField",
  "name": "logic",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "QuestionLogicRequiredAnswer",
      "kind": "LinkedField",
      "name": "requiredAnswers",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "questionId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "answerIds",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "needsConfirmation",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notice",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "preSelectedOptionValue",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subtitle",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "Option",
  "kind": "LinkedField",
  "name": "options",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sublabel",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subValue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "score",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useNativeSurveyQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "nativeSurvey",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Question",
                "kind": "LinkedField",
                "name": "questions",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "SingleChoiceRenderer_question"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "TextRenderer_question"
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "NativeSurvey",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useNativeSurveyQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "nativeSurvey",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Question",
                "kind": "LinkedField",
                "name": "questions",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "NativeSurvey",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b39db3ca1d1f86e523c8aad492207a3a",
    "id": null,
    "metadata": {},
    "name": "useNativeSurveyQuery",
    "operationKind": "query",
    "text": "query useNativeSurveyQuery(\n  $id: ID\n  $title: NativeSurveyTitle\n) {\n  nativeSurvey(id: $id, title: $title) {\n    __typename\n    ... on NativeSurvey {\n      id\n      questions {\n        id\n        groupId\n        header\n        logic {\n          requiredAnswers {\n            questionId\n            answerIds\n          }\n        }\n        needsConfirmation\n        notice\n        preSelectedOptionValue\n        title\n        subtitle\n        type\n        options {\n          label\n          sublabel\n          subValue\n          score\n          value\n        }\n        ...SingleChoiceRenderer_question\n        ...TextRenderer_question\n      }\n    }\n  }\n}\n\nfragment SingleChoiceRenderer_question on Question {\n  id\n  options {\n    label\n    sublabel\n    value\n    subValue\n  }\n}\n\nfragment TextRenderer_question on Question {\n  id\n  options {\n    label\n    sublabel\n    value\n  }\n}\n"
  }
};
})();

(node as any).hash = "ff6191c21dba8ae140e81e6bec39b002";

export default node;
